.flipCard {
  perspective: 1000px;
}

.flipCardInner,
.onlyFrontCard {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flipCard:hover .flipCardInner {
  transform: rotateY(180deg);
}

.flipCardFront,
.flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flipCardFront {
  display: flex;
  flex-direction: column;
}

.flipCardBack {
  transform: rotateY(180deg);
}
