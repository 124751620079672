.MuiPickersCalendarHeader-label {
  color: #e6e6e6;
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiPickersCalendarHeader-switchViewIcon,
.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium.MuiPickersArrowSwitcher-button {
  color: #fff;
}
.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeMedium.MuiPickersArrowSwitcher-button {
  color: #fff;
}
.MuiButtonBase-root.Mui-disabled.MuiIconButton-root.Mui-disabled.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium.MuiPickersArrowSwitcher-button {
  color: rgba(255, 255, 255, 0.3);
}

.MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel {
  color: rgba(255, 255, 255, 0.7);
}
.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
  color: #e6e6e6;
}
.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin:hover {
  background-color: rgba(144, 202, 249, 0.08);
}
.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today:not(
    .Mui-selected
  ) {
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.MuiDateRangeCalendar-monthContainer
  .MuiTypography-root.MuiTypography-subtitle1 {
  color: #e6e6e6;
}
.MuiButtonBase-root.MuiPickersDay-root.MuiDateRangePickerDay-day.MuiDateRangePickerDay-notSelectedDate.MuiDateRangePickerDay-dayOutsideRangeInterval {
  color: #e6e6e6;
}
.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-today.MuiDateRangePickerDay-day.MuiDateRangePickerDay-notSelectedDate.MuiDateRangePickerDay-dayOutsideRangeInterval {
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiDateRangePickerDay-day {
  color: rgba(0, 0, 0, 0.87);
  background-color: #90caf9;
  font-weight: 500;
}
.MuiButtonBase-root.MuiPickersDay-root.MuiDateRangePickerDay-day.MuiDateRangePickerDay-notSelectedDate.MuiDateRangePickerDay-dayInsideRangeInterval {
  color: #e6e6e6;
}
.MuiButtonBase-root {
  color: #fff !important;
}
